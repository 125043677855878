import React from 'react'
import { PlateFormat } from '@models/index';
import { Accordion, AccordionContent, AccordionTitle, Divider, Grid, Icon, Responsive } from 'semantic-ui-react';
import { CombinationPricingOptionsContainer, CombinationPricingOptionsHeading, CombinationPricingOptionContainer, CombinationPricingOptionsImage, CombinationPricingOptionsPrice, CombinationPricingOptionsDescription, SelectedCombinationPricingOptionContainer, CombinationPricingOptionsExampleText, CombinationPricingOptionsPriceContainer, CombinationPricingDisclaimer, CombinationPricingOptionsOuterWrapper, LeftColumn } from './CombinationPricingOptions.styles';
import { H6 } from '@styles/Global.styles';
import theme from '@styles/theme';
import { getPlateFormatExampleCombinations, getPlateFormat } from '@utils/Helpers';
import { AfterpayMessage } from '@utils/Afterpay';
import { getCurrentCheckout } from '@redux/localStorage/checkout';
import { CombinationPaymentList } from './CombinationPaymentList';
import { number } from '@storybook/addon-knobs';


interface CombinationPricingOptionsProps {
    combinationFormats: PlateFormat[]
}

interface CombinationPricingOptionsState {
    selectedCombinationFormatId?: number,
    activeIndex: number
}

/* eslint-disable jsx-a11y/label-has-associated-control */
class CombinationPricingOptions extends React.Component<CombinationPricingOptionsProps, CombinationPricingOptionsState>{

    constructor(props: CombinationPricingOptionsProps) {
        super(props);
        this.state = {
            selectedCombinationFormatId: undefined,
            activeIndex: -1
        }
    }

    handleClick = (e : any, titleProps : any) => {
        const { index } = titleProps
        const newIndex = this.state.activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    selectCombinationFormat = (combination: string) => {
        const plateFormatName = getPlateFormat(combination);

        this.props.combinationFormats.forEach((plateFormat, index) => {
            if (plateFormatName.includes(plateFormat.formatType.slice(0, plateFormat.formatType.indexOf(" ")))) {
                this.setState({
                    selectedCombinationFormatId: index
                })
            }
        });
    }

    clearSelectCombinationFormat = () => {
        this.setState({
            selectedCombinationFormatId: undefined
        })
    }

    render() {
        const { selectedCombinationFormatId } = this.state;
        const { combinationFormats } = this.props;
        const checkout = getCurrentCheckout();

        return (
            <CombinationPricingOptionsOuterWrapper>
                <Responsive minWidth={theme.responsive.tablet.minWidth} >
                    <CombinationPricingOptionsContainer>
                        <CombinationPricingOptionsHeading>
                            {selectedCombinationFormatId === undefined ? 'Plate Pricing Options' : 'Plate Pricing Options'}
                        </CombinationPricingOptionsHeading>
                        {
                            selectedCombinationFormatId !== undefined &&
                            <CombinationPricingOptionContainer>
                                <CombinationPricingOptionsDescription>
                                    Your combination is a:
                                </CombinationPricingOptionsDescription>
                                <SelectedCombinationPricingOptionContainer>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column mobile={16} tablet={16} computer={7} largeScreen={7} widescreen={7}>
                                                <H6>
                                                    {combinationFormats[selectedCombinationFormatId].name}
                                                </H6>
                                            </Grid.Column>
                                            <CombinationPricingOptionsPriceContainer mobile={16} tablet={16} computer={16} largeScreen={9} widescreen={9}>
                                                <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                                    <label>
                                                        From
                                                    </label>
                                                    <span>
                                                        ${combinationFormats[selectedCombinationFormatId].basePrice}
                                                    </span>
                                                </CombinationPricingOptionsPrice>
                                            </CombinationPricingOptionsPriceContainer>
                                            {
                                                combinationFormats[selectedCombinationFormatId].basePrice <= 2000 && !checkout.isDealerOrder &&
                                                <Grid.Column width={16}>
                                                    <CombinationPaymentList basePrice={combinationFormats[selectedCombinationFormatId].basePrice}/>
                                                </Grid.Column>
                                            }

                                            <Grid.Column width={16}>
                                                <CombinationPricingOptionsDescription>
                                                    {combinationFormats[selectedCombinationFormatId].description}
                                                </CombinationPricingOptionsDescription>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </SelectedCombinationPricingOptionContainer>
                            </CombinationPricingOptionContainer>
                        }
                        {
                            combinationFormats.map((combinationFormat, index) => {
                                if (index >= 3){
                                    return;
                                }
                                const laybyFromPrice = (combinationFormat.basePrice / 12);
                                return (
                                    selectedCombinationFormatId !== index &&
                                    <CombinationPricingOptionContainer key={index}>
                                        {/* <CombinationPricingOptionsDescription>
                                            <span className="other-description">
                                            Other available combination formats:
                                            </span>
                                        </CombinationPricingOptionsDescription> */}                                        
                                        <Grid columns={2}>
                                            <Grid.Row>
                                                <Grid.Column width={8}>
                                                    <H6>
                                                        {combinationFormat.name}
                                                    </H6>                                                    
                                                </Grid.Column>
                                                <CombinationPricingOptionsPriceContainer width={8}>
                                                    <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                                        <label>
                                                            From
                                                        </label>
                                                        <span>
                                                            ${combinationFormat.basePrice}
                                                        </span>
                                                    </CombinationPricingOptionsPrice>
                                                </CombinationPricingOptionsPriceContainer>

                                                {
                                                    combinationFormat.basePrice <= 2000 && !checkout.isDealerOrder &&
                                                    <Grid.Column width={16}>
                                                        <CombinationPaymentList basePrice={combinationFormat.basePrice}/>
                                                    </Grid.Column>
                                                }
                                                <Grid.Column width={16}>
                                                    <CombinationPricingOptionsDescription>
                                                        {combinationFormat.description}
                                                    </CombinationPricingOptionsDescription>
                                                </Grid.Column>
                                                {
                                                    selectedCombinationFormatId === undefined &&
                                                    <Grid.Column width={16}>
                                                        <CombinationPricingOptionsExampleText>Examples:</CombinationPricingOptionsExampleText>
                                                        <div>
                                                            {
                                                                getPlateFormatExampleCombinations(combinationFormat.name).map((exampleCombination, index) => {
                                                                    return (
                                                                        <CombinationPricingOptionsImage key={index}>
                                                                            <span>{exampleCombination}</span>
                                                                        </CombinationPricingOptionsImage>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Grid.Column>
                                                }

                                            </Grid.Row>
                                        </Grid>
                                    </CombinationPricingOptionContainer>
                                )
                            })
                        }
                        <CombinationPricingDisclaimer>
                            * Layby prices reflect the weekly Layby plan
                        </CombinationPricingDisclaimer>
                    </CombinationPricingOptionsContainer>
                </Responsive>
                <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                    <CombinationPricingOptionsHeading>
                        {selectedCombinationFormatId === undefined ? 'Plate Pricing Options' : 
                    <Grid columns={2}>                    
                        <LeftColumn width={8}>
                            <H6>
                                {combinationFormats[selectedCombinationFormatId].name}
                            </H6>
                        </LeftColumn>
                        <CombinationPricingOptionsPriceContainer width={8} >
                            <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                <label color='#eee'>
                                    From
                                </label>
                                <span>
                                    ${combinationFormats[selectedCombinationFormatId].basePrice}
                                </span>
                            </CombinationPricingOptionsPrice>
                        </CombinationPricingOptionsPriceContainer>
                    </Grid>
                    }
                    </CombinationPricingOptionsHeading>
                    <Accordion className='outer-wrapper' >
                        <AccordionTitle className='accordion-title'
                        active={this.state.activeIndex === 0}
                        index={0}
                        onClick={this.handleClick} 
                        >                        
                        View format prices {this.state.activeIndex === 0 ? <Icon name='chevron up'/> : <Icon name='chevron down'/>} 
                        </AccordionTitle>
                        <AccordionContent active={this.state.activeIndex === 0} className='accordion-content'>
                            {
                                selectedCombinationFormatId !== undefined &&
                                <CombinationPricingOptionContainer>
                                    <SelectedCombinationPricingOptionContainer>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={8}>
                                                    <H6>
                                                        {combinationFormats[selectedCombinationFormatId].name}
                                                    </H6>
                                                </Grid.Column>
                                                <CombinationPricingOptionsPriceContainer width={8}>
                                                    <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                                        <label>
                                                            From
                                                        </label>
                                                        <span>
                                                            ${combinationFormats[selectedCombinationFormatId].basePrice}
                                                        </span>
                                                    </CombinationPricingOptionsPrice>
                                                </CombinationPricingOptionsPriceContainer>
                                                {
                                                    combinationFormats[selectedCombinationFormatId].basePrice <= 2000 && !checkout.isDealerOrder &&
                                                    <Grid.Column width={16}>
                                                        <CombinationPaymentList basePrice={combinationFormats[selectedCombinationFormatId].basePrice}/>
                                                    </Grid.Column>
                                                }

                                                <Grid.Column width={16}>
                                                    <CombinationPricingOptionsDescription>
                                                        {combinationFormats[selectedCombinationFormatId].description}
                                                    </CombinationPricingOptionsDescription>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </SelectedCombinationPricingOptionContainer>
                                </CombinationPricingOptionContainer>
                            }
                            {
                                combinationFormats.map((combinationFormat, index) => {
                                    if (index >= 3){
                                        return;
                                    }
                                    const laybyFromPrice = (combinationFormat.basePrice / 12);
                                    return (
                                        selectedCombinationFormatId !== index &&
                                        <CombinationPricingOptionContainer key={index}>
                                            {/* <CombinationPricingOptionsDescription>
                                                <span className="other-description">
                                                Other available combination formats:
                                                </span>
                                            </CombinationPricingOptionsDescription> */}                                            
                                            <Grid >
                                                <Grid.Row>                                                    
                                                    <Grid.Column width={8}>
                                                        <H6>
                                                            {combinationFormat.name}
                                                        </H6>
                                                    </Grid.Column>
                                                    <CombinationPricingOptionsPriceContainer width={8}>
                                                        <CombinationPricingOptionsPrice color={theme.brand.colors.green}>
                                                            <label>
                                                                From
                                                            </label>
                                                            <span>
                                                                ${combinationFormat.basePrice}
                                                            </span>
                                                        </CombinationPricingOptionsPrice>
                                                    </CombinationPricingOptionsPriceContainer>    
                                                    {
                                                        combinationFormat.basePrice <= 2000 && !checkout.isDealerOrder &&
                                                        <Grid.Column width={16}>
                                                            <CombinationPaymentList basePrice={combinationFormat.basePrice}/>
                                                        </Grid.Column>
                                                    }
                                                    <Grid.Column width={16}>
                                                        <CombinationPricingOptionsDescription>
                                                            {combinationFormat.description}
                                                        </CombinationPricingOptionsDescription>
                                                    </Grid.Column>
                                                    {
                                                        selectedCombinationFormatId === undefined &&
                                                        <Grid.Column width={16}>
                                                            <CombinationPricingOptionsExampleText>Examples:</CombinationPricingOptionsExampleText>
                                                            <div>
                                                                {
                                                                    getPlateFormatExampleCombinations(combinationFormat.name).map((exampleCombination, index) => {
                                                                        return (
                                                                            <CombinationPricingOptionsImage key={index}>
                                                                                <span>{exampleCombination}</span>
                                                                            </CombinationPricingOptionsImage>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Grid.Column>
                                                    }
                                                    {index < 2 && <Divider clearing className='price-options-divider'/> }
                                                </Grid.Row>
                                            </Grid>
                                        </CombinationPricingOptionContainer>
                                    )
                                })
                            }
                            <CombinationPricingDisclaimer>
                                * Layby prices reflect the weekly Layby plan
                            </CombinationPricingDisclaimer>
                        </AccordionContent>
                    </Accordion>
                </Responsive>  
            </CombinationPricingOptionsOuterWrapper>
        )
    }
}

export default CombinationPricingOptions;